import React from "react"
import { Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/VeriottLogo.png"

const MPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <div className="intro">
      <img className="veriottLogo" src={logo} alt="veriott logo"></img>
    </div>

    <div>
    <h2 style={{marginBottom:"0.5em",backgroundColor:"#ff686b",display:"inline",}}>/members</h2>
    
    <p>Welcome to the members area. You have access to this because we believe in you, <u>don't forget that.</u></p>

      <h4 style={{marginBottom:"0.3em",}}>
        Shopify snipets & more</h4>
        <p>- <Link style={{marginBottom:"0.5em"}} to="/FOCm37">Cookie banner</Link>
        <br></br>
        <i>
          Become "almost" compliant with cookies and stop missing out on sales!
        </i></p>
        <br></br>
        - <Link style={{marginBottom:"0.5em"}} to="/giveaway-schedule">giveaway schedule</Link>
        <br></br>
        - <Link style={{marginBottom:"0.5em"}} to="/giveaway-schedule"> schedule</Link>

      <br></br>
      <br></br>

        <h4 style={{marginBottom:"0.3em",}}>
        Schedules</h4>
        - <Link style={{marginBottom:"0.5em"}} to="/schedule">schedule</Link>
        <br></br>
        - <Link style={{marginBottom:"0.5em"}} to="/giveaway-schedule">giveaway schedule</Link>
        <br></br>
        - <Link style={{marginBottom:"0.5em"}} to="/giveaway-schedule"> schedule</Link>





    </div>
  </Layout>
)

export default MPage
